.pa-field-wrapper{
  position:relative;
  margin-bottom:40px;
  width:50%;
}

.pa-field-wrapper.full{
  width:100%;
}

.pa-field-label{
  display:flex;
  opacity:1;
  align-items: center;
  margin-bottom:10px;
}

.pa-input{
  border:2px solid transparent;
  background-color:var(--light-grey);
  padding:6px 10px;
  margin:5px 0;
  font-size:0.9em;
  width:80%;
  transition:border 200ms ease;
}

.pa-input:focus,
.pa-input:focus-visible{
  border:2px solid var(--primary);
  outline:none;
}

.pa-input.invert:focus,
.pa-input.invert:focus-visible{
  border:2px solid var(--success);
  outline:none;
}

.pa-textarea{
  height: 100px;
  width: 100%;
  border:2px solid transparent;
  background-color:var(--light-grey);
  padding:6px 10px;
  margin:5px 0;
  font-size:0.9em;
  font-family: inherit;
  transition:border 200ms ease;
}

.pa-textarea:focus,
.pa-textarea:focus-visible{
  border:2px solid var(--primary);
  outline:none;
}

.pa-checkbox-label{
  color: var(--primary);
  font-size:0.9em;
  margin-left:5px;
}

.pa-richtext-wrapper {
  margin-bottom:40px;
}
.pa-richtext-editor {
  color: var(--dark);
  background-color:#EBEFFA;
  padding: 1rem;
  border: 1px solid #ccc;
  min-height:200px;
}
.pa-richtext-wrapper .pa-richtext-toolbar {
  border: 1px solid #ccc;
  margin-bottom: 0;
}

.pa-richtext-blocktype-dropdown{
  color:black;
}
.rdw-link-modal{
  color:black;
  height:auto !important;
}
.pa-cols-wrapper {
  gap: 40px
}

.pa-multiple-file-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;

}

.pa-multiple-file-input {
  display: inline-block;
}

.pa-multiple-file-preview {
  position: relative;
}

.pa-multiple-file-preview-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top:33px;
}

.pa-multiple-file-preview-wrapper .pa-multiple-file-preview img {
  max-width: 160px;
  display: flex;
}

.pa-single-file-preview {
  position: relative;
  margin-top: 33px;
}

.pa-single-file-preview img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
}

.pa-single-file-wrapper {
  display: flex;
  justify-content:start;
  align-items: start;
}

.pa-field-label-file {
  border: 0.5px solid var(--grey);
  background-color: var(--light-grey);
  padding: 15px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin: 10px 20px 0 0;
  border-radius:4px;
}

.pa-file-input-icon {
  width: 60px;
}

.pa-file-input-icon img{
  width: 100%;
  height: 100%;
}

.pa-file-input-text {
  color: #A2A2A2;
  margin: 0;
  text-align:center;
  margin-top:5px;
}

.pa-file-input{
  position:absolute;
  opacity:0;
  top:0;
  width:100%;
  height:100%;
  cursor:pointer;
}

.pa-preview-delete-wrapper {
  position: absolute;
  right: -10px;
  top: -10px;
}

.pa-form-timeline-wrapper{
  width:100%;
  display:flex;
  justify-content: center;
  cursor:pointer;
  min-height:60px;
}

.pa-timeline-container{
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
}

.pa-timeline-item{
  position:relative;
  min-width:100px;
  margin-bottom:15px;
}

.pa-timeline-item.success .pa-timeline-item-line,
.pa-timeline-item.success .pa-timeline-item-number{
  background:var(--success);
}

.pa-timeline-item.warning .pa-timeline-item-line,
.pa-timeline-item.warning .pa-timeline-item-number{
  background:var(--warning);
}

.pa-timeline-item.danger .pa-timeline-item-line,
.pa-timeline-item.danger .pa-timeline-item-number{
  background:var(--danger);
}

.pa-timeline-item:hover .pa-timeline-item-title{
  opacity:1;
}

.pa-timeline-item-title{
  max-width:200px;
  width:max-content;
  padding:10px;
  border-radius:4px;
  background:white;
  position:absolute;
  bottom:120%;
  opacity:0;
  pointer-events: none;
  transition:opacity 300ms ease;
  font-size:1em;
  box-shadow: 0 0 20px 5px rgba(0,0,0,0.2);
  z-index:999;
  color:black;
}

.pa-timeline-item-here{
  flex-direction:column;
  display:none;
}

.pa-timeline-item.current .pa-timeline-item-here{
  display:flex;
}

.pa-timeline-item-here .caret{
  margin-left:5px;
}

.pa-timeline-item.warning .pa-timeline-item-here .caret{
  color:var(--warning);
}

.pa-timeline-item.danger .pa-timeline-item-here .caret{
  color:var(--danger);
}

.pa-timeline-item.success .pa-timeline-item-here .caret{
  color:var(--success);
}

.pa-timeline-item-here span{
  font-size:0.7em;
}

.pa-timeline-item-head{
  display:flex;
  align-items: center;
}

.pa-timeline-item-number{
  width:25px;
  height:25px;
  border-radius: 50%;
  background:var(--primary);
  color:var(--light);
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;
  z-index:2;
  flex-shrink:0;
}

.pa-timeline-item-line{
  width:30px;
  height:6px;
  margin:0 5px;
  background:var(--grey);
  width:100%;
  border-radius:5px;
}

.pa-repeater-button{
  background-color: var(--primary);
  color: var(--light);
  padding: 5px 15px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.8em;
  display:inline-block;
  outline:none;
  border:none;
  opacity:1;
  transition:opacity 200ms ease;
}
.pa-repeater-button:disabled{
  opacity:0.3;
  cursor:not-allowed;
}

.pa-repeater-row-wrap{
  margin-bottom:20px;
}

.pa-repeater-row-wrap .pa-field-wrapper{
  margin-bottom: 0;
}

.pa-repeater-row-remove{
  background-color: var(--warning);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink:0;
}

.pa-social-media-repeater-row + .pa-repeater-row-remove{
  margin-top: 23px;
}

.pa-colorpicker-field-wrapper {
  display: inline-block;
  margin-right: 50px;
  clear:none;
}

.pa-colorpicker-wrapper {
  position: absolute;
}

.pa-colorpicker-btn {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  max-width: 250px;
  background-color:#EBEFFA;
  border: 1px solid #ccc;
  align-items: center;
  cursor: pointer;
  margin-top:20px;
}

.pa-colorpicker-btn-preview {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.pa-colorpicker-btn-text {
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
}

.pa-selecttexttextarea-wrap{
  display:flex;
  width:100%;
  margin-right:20px;
}

@media screen and (max-width:750px){
  .pa-form-timeline-wrapper{
    display:none;
  }
  .pa-selecttexttextarea-wrap{
    flex-wrap:wrap;
    padding:5px;
    background:var(--grey);
  }
}