.pa-dashboard-button{
  padding:10px;
  background: var(--primary);
  color: var(--light);
  border-radius: 20px;
  cursor:pointer;
  display:inline-block;
}

.pa-company-table-item{
  padding: 15px 20px;
  margin-bottom: 8px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 6px 6px 30px 0px rgba(0,0,0,0.25);
}

.pa-company-table-item-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
}

.pa-left-container{
  max-width:1200px;
  margin-left: 40px;
}

.pa-company-table-head{
  background:#EBEFFA;
  margin-bottom:-10px;
  position:relative;
  z-index:0;
  padding:10px 40px 20px 10px;
  border-radius:8px;
  display:flex;
}

.pa-company-table-head-title{
  width:30%;
  text-transform: uppercase;
  opacity:0.7;
  cursor:pointer;
  display:flex;
  align-items:center;
}

.pa-company-table-item-theme{
  text-transform: uppercase;
  font-size:13px;
  opacity:0.6;
}

.pa-table-form-link{
  color:var(--primary);
  font-size:14px;
  margin-top:10px;
  display:block;
}

.pa-company-table-logs-head{
  background:#EBEFFA;
  border-radius:8px;
  margin-bottom:-10px;
  padding:5px 10px 15px;
  display:flex;
  text-transform: uppercase;
}

.pa-company-table-logs-head span{
  opacity:0.6;
  font-size:12px;
}

.pa-company-table-logs-content{
  background:var(--grey);
  font-size:15px;
  border-radius:8px;
  padding:10px;
}

.pa-company-table-logs-item{
  width:100%;
  display:flex;
  align-items:center;
  margin:5px 0;
}

.pa-company-table-logs-item .date{
  width:20%;
  font-size:0.8em;
}

.pa-company-table-logs-item .user{
  width:20%;
  font-size:0.8em;
}

.pa-company-table-logs-item .log{
  width:60%;
}

@media screen and (max-width:980px){
  .pa-company-table-logs-item{
    flex-wrap: wrap;
    margin:10px 0;
    justify-content: flex-start;
  }
  .pa-company-table-logs-item .date{
    width:100%;
    margin-right:20px;
  }
  .pa-company-table-logs-item .user{
    width:30%;
  }
  
  .pa-company-table-logs-item .log{
    width:70%;
  }
}

.pa-company-table-logs{
  margin-bottom:20px;
}

.pa-company-table-log-add{
  margin-top:20px;
  display:flex;
}

.pa-logs-wrap{
  max-height:300px;
}

.pa-logs-input{
  width:100%;
  border-radius:4px;
  border:none;
  padding-left:5px;
  height:30px;
  margin-right:10px;
}

.pa-search-input{
  width:50%;
  border-radius:4px;
  border:none;
  padding-left:5px;
  height:40px;
  margin:0 auto;
  display:block;
}

.pa-logs-btn{
  border:none;
  border-radius:4px;
}

.pa-logs-input:focus{
  outline:none;
}

.pa-company-table-info{
  background:var(--grey);
  padding:10px;
  border-radius:8px;
}

.pa-filters-container{
  padding:20px;
  background-color: var(--grey);
  border-radius:8px;
  color:white;
}

.pa-company-filters-select{
  color:var(--dark);
  min-width:200px;
  max-width: 400px;
}

.pa-filter-button{
  font-size:25px;
  margin-left:10px;
  cursor:pointer;
}

.pa-company-page-section{
  background:#EBEFFA;
  padding:20px;
  border-radius:8px;
  width:-webkit-fill-available;
  margin:10px;
}

.pa-company-info-edit{
  display:inline-block;
}

.pa-company-info-edit-input,
.pa-company-info-edit-input:focus{
  border:none;
  outline:none;
  background:transparent;
}

.pa-company-info-edit-btn:disabled{
  opacity:0;
}

.pa-company-info-edit-btn{
  outline:none;
  border:none;
  background:var(--grey);
  color:white;
  border-radius:4px;
  transition:opacity 200ms ease;
}