:root {
    --primary: #0069B1;
    --secondary: #408EC4;
    --accent: #fa9833;
    --dark: #000000;
    --light: #FFFFFF;
    --light-grey: #F5F5FD;
    --grey: #87B6DB;
    --success: #15CB3D;
    --warning: #F19204;
    --danger: #DB2828;
    --border-radius: 4px;
  }