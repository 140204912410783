/* Colors
// ================================================== */
.c--primary{ color:var(--primary); }
.c--secondary{ color:var(--secondary); }
.c--accent{ color:var(--accent); }
.c--dark{ color:var(--dark); }
.c--light{ color:var(--light); }
.c--lightgrey{ color:var(--light-grey); }
.c--grey{ color:var(--grey); }
.c--black{ color:black; }
.c--white{ color:white; }

.bg--primary{ background-color:var(--primary); }
.bg--secondary{ background-color:var(--secondary); }
.bg--accent{ background-color:var(--accent); }
.bg--dark{ background-color:var(--dark); }
.bg--light{ background-color:var(--light); }
.bg--lightgrey{ background-color:var(--light-grey); }
.bg--grey{ background-color:var(--grey); }
.bg--black{ background-color:black; }
.bg--white{ background-color:white; }
.bg--warn{background-color:var(--warning);}


/* Sizing
// ================================================== */
.w--100{ width:100%; }
.h--100{ height:100%; }

/* Spacing
// ================================================== */
.p--5{ padding:5px; }
.px--5{ padding-left:5px; padding-right:5px; }
.py--5{ padding-top:5px; padding-bottom:5px; }
.p--10{ padding:10px; }
.px--10{ padding-left:10px; padding-right:10px; }
.py--10{ padding-top:10px; padding-bottom:10px; }
.p--20{ padding:20px; }
.px--20{ padding-left:20px; padding-right:20px; }
.py--20{ padding-top:20px; padding-bottom:20px; }

/* Flexbox
// ================================================== */
.d--f  { display: flex; }
.d--if { display: inline-flex; }

/* Flex direction */
.fd--r  { flex-direction: row; } /* Default */
.fd--rr { flex-direction: row-reverse; }
.fd--c  { flex-direction: column; }
.fd--cr { flex-direction: column-reverse; }


/* Flex wrap */
.fw--n  { flex-wrap: nowrap; } /* Default */
.fw--w  { flex-wrap: wrap; }
.fw--wr { flex-wrap: wrap-reverse; }


/* Justify content */
.jc--fe { justify-content: flex-end; }
.jc--fs { justify-content: flex-start; } /* Default */
.jc--c  { justify-content: center; }
.jc--sa { justify-content: space-around; }
.jc--sb { justify-content: space-between; }


/* Align items */
.ai--fs { align-items: flex-start; }
.ai--c  { align-items: center; }
.ai--fe { align-items: flex-end; }
.ai--s  { align-items: stretch; } /* Default */
.ai--b  { align-items: baseline; }


/* Flex grow */
.fg--0 { flex-grow: 0; } /* Default */
.fg--1 { flex-grow: 1; }


/* Flex shrink */
.fs--0 { flex-shrink: 0; }
.fs--1 { flex-shrink: 1; } /* Default */


/* Flex basis */
.fb--a { flex-basis: auto; } /* Default */
.fb--0 { flex-basis: 0; }


/* Align self */
.as--a  { align-self: auto; } /* Default */
.as--fs { align-self: flex-start; }
.as--c  { align-self: center; }
.as--fe { align-self: flex-end; }
.as--s  { align-self: stretch; }
.as--b  { align-self: baseline }

/* Text
// ================================================== */
.ta--c{
  text-align:center;
}

.fwe--bold { 
  /* font-family: MontserratBold; */
  font-weight:800;
}
.fwe--medium { 
  /* font-family: MontserratMedium; */
  font-weight:600;
}
.fwe--normal { 
  /* font-family: Montserrat; */
  font-weight:400;
} /* Default */
.fwe--light { 
  /* font-family: MontserratLight; */
  font-weight:300;
}

.ft-normal { font-style: normal; } /* Default */
.ft-italic { font-style: italic; }

.p--big{
  font-size:1.3em;
  line-height:1.3em;
}

/* Backgrounds
// ================================================== */
.has-journey-backdrop-graphics{
  background-image:url('../img/pa-web-journey-backdrop-graphics.svg');
  background-size:cover;
  background-position:center;
}

.has-journey-backdrop-graphics.light{
  background-image:url('../img/pa-web-journey-backdrop-graphics-light.svg');
}

/* Scroll
// ================================================== */
.pa-scroll-container{
overflow-y:auto;
overflow-x:hidden;
scrollbar-color: var(--primary) #E4EDFA;
scrollbar-width: thin;
}

.pa-scroll-container.dark{
  scrollbar-color: #E4EDFA var(--primary);
}

.pa-scroll-container::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px #E4EDFA;
background-color: #E4EDFA;
}

.pa-scroll-container.dark::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px var(--primary);
background-color: var(--primary);
}

.pa-scroll-container::-webkit-scrollbar
{
width: 6px;
background-color: #E4EDFA;
}

.pa-scroll-container.dark::-webkit-scrollbar
{
background-color: var(--primary);
}

.pa-scroll-container::-webkit-scrollbar-thumb
{
background-color: var(--primary);
border-radius:4px;
}

.pa-scroll-container.dark::-webkit-scrollbar-thumb
{
background-color: #E4EDFA;
}