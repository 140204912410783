.pa-tooltip{
  position:relative;
  margin-left:10px;
}

.pa-tooltip-icon{
  font-size: 18px;
  margin-top:-2px;
  cursor:pointer;
}

.pa-tooltip-description{
  position:absolute;
  bottom:100%;
  left:-5px;
  background:var(--secondary);
  border-radius:4px;
  color:var(--light);
  padding:5px 25px 8px 8px;
  z-index:999;
  box-shadow: 0 0 20px 0px #cedbed;
  margin-bottom:5px;
  opacity:0;
  pointer-events:none;
  cursor:pointer;
  width:max-content;
  max-width:300px;
  font-size:14px;
  line-height:14px;
  transition:opacity 100ms ease;
}

.pa-tooltip-description.active{
  opacity:1;
  pointer-events: all;
}

.pa-tooltip-description::after{
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 15px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--secondary) transparent transparent transparent;
}

.pa-tooltip-close{
  float:right;
  margin-right:-20px;
}

.pa-repeater-controls{
  width:30px;
  color:var(--grey);
  cursor:pointer;
}