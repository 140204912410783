.pa-form-steps-wrapper{
  position:relative;
}

.standard-form-container {
  width: 100%;
  min-height: 100vh;
  background-color: var(--light-grey);
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-end;
}

.pa-form-content-area{
  height:100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  flex-grow:1;
  width:100%;
}

.standard-form-wrapper {
  width: 100%;
  height: 100%;
  max-height:60vh;
  min-height:50vh;
  padding: 20px 30px;
  box-shadow: 0 0 20px 10px #cedbed;
  position:relative;
}

.pa-standard-form-fields-wrapper{
  display:flex;
  flex-wrap: wrap;
  margin-top:50px;
}

.standard-form-title {
  width: 100%;
  text-align: left;
  margin: 0 0 18px 0;
  z-index: 1;
  font-size:1.6em;
}
.pa-preview-button{
  cursor:pointer;
  color:var(--secondary);
  font-weight: 500;
  margin-right:25px;
  margin-bottom:-113px;
  z-index:99;
}
.pa-service-step.open .pa-preview-button{
  margin-right:0;
  margin-bottom:0;
}
.pa-form-step-description{
  margin-right:100px;
}
.pa-preview-button svg{
  margin-left:5px;
  margin-bottom:-3px;
}

.pa-form-nav-button{
  background-color: var(--light);
  color: var(--primary);
  padding:2px 20px;
  border-radius:25px;
  cursor:pointer;
  font-size:1.2em;
  display:flex;
  align-items: center;
  outline:none;
  border:none;
}

.pa-form-nav-button:disabled{
  opacity:0.6;
  cursor:not-allowed;
}

.pa-form-save-button{
  position: absolute;
  right: 100px;
  top: 80px;
  background-color: var(--primary);
  color: var(--light);
  padding:10px 25px;
  border-radius:25px;
  cursor:pointer;
  font-size:1.2em;
  display: inline-block;
  border: none;
  z-index: 100;
}

.pa-splitscreen-container {
  display: flex;
  position: relative;
  min-height:100vh;
}

.pa-fullscreen-container {
  display: flex;
  color: var(--light);
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  background-color: var(--primary);
}

.pa-fullscreen-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 800px;
}

.pa-splitscreen-col {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0 2%;
  position:relative;
}

.pa-splitscreen-img {
  width: auto;
  max-height:70vh;
  margin-bottom:30px;
  max-width:100%;
}

.pa-splitscreen-img img {
  width: 100%;
}

.pa-splitscreen-footnote {
  bottom: 30px;
  text-align: center;
  padding: 0 20px 0 20px;
  font-size: 0.9em;
}

.pa-footnote-icon {
  height: 12px;
  width: 12px;
  display: inline-block;
  margin: 0 0 0 8px;
}

.pa-splitscreen-title {
  text-align: center;
  font-size:1.6em;
}

.pa-splitscreen-description {
  text-align: center;
}

.pa-splitscreen-step-number{
  width:60px;
  height:60px;
  border-radius:50%;
  background:var(--light);
  color:var(--dark);
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:30px;
}

.pa-splitscreen-explanation-title{
  font-size:1.2em;
}

.pa-splitscreen-explanation{
  font-size:0.8em;
  text-align:center;
  margin-bottom:20px;
}

.pa-form-step-nav-container{
  position:relative;
  z-index:2;
  display:flex;
  justify-content: flex-end;
  margin-top:10px;
  width:100%;
}

.pa-form-step-nav-container.center{
  justify-content: center;
}

.pa-fullscreen-nav-btn-container {
  position:relative;
  z-index:2;
  display:flex;
  justify-content:center;
  margin-top:30px;
  width:100%;
  gap: 20px;
}

/*
* Services Steps
*/

.add-service-wrapper{
  position:absolute;
  z-index:2;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}

.pa-item-wrapper {
  position: relative;
  margin: 0 20px 20px 0;

}

.pa-item {
  position: relative;
  background-color: #EBEFFA;
  display: inline-block;
  border: 0.5px solid var(--grey);
  padding: 13px 20px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius:4px;
}

.pa-item.reverse {
  background-color: #EBEFFA;
  color: var(--dark);
  display: inline-block;
  border: 2px solid transparent;
  padding: 13px 20px;
  cursor: pointer;
  margin-right: 20px;
}

.pa-item.reverse.active {
  border: 2px solid var(--success);
  box-shadow:inset 0px 0px 0px 1px var(--light)
}

.pa-item-delete-btn,
.pa-item-edit-btn{
  width: 20px;
  height: 20px;
  background-color: var(--grey);
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

.pa-item-edit-btn{
  right:30px;
}

.pa-toc-container{
  width:100%;
  margin:50px 0px;
  display:flex;
  flex-wrap:wrap;
  max-height:60vh;
  overflow-y:scroll;
  padding-right:5px;
}

.pa-toc-item{
  width:20%;
  padding:10px;
}

.pa-toc-item h3{
  font-weight:400;
  font-size:1em;
  margin-top:5px;
}

.pa-toc-item-header{
  display:flex;
  align-items: center;
  position:relative;
}

.pa-toc-item-number{
  width:45px;
  height:45px;
  border-radius:100%;
  background:var(--light);
  color:var(--dark);
  flex-shrink:0;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:20px;
  font-weight:600;
}

.pa-toc-item.success .pa-toc-item-number{
  background:var(--success);
  color:var(--light);
}

.pa-toc-item.warning .pa-toc-item-number{
  background:var(--warning);
  color:var(--light);
}

.pa-toc-item.danger .pa-toc-item-number{
  background:var(--danger);
  color:var(--light);
}

.pa-toc-item-line{
  height:6px;
  width:100%;
  border-radius:4px;
  background:#62A1CF;
  margin:0px 10px;
}

.pa-toc-item.success .pa-toc-item-line{
  background:var(--success);
  opacity:0.9;
}

.pa-toc-item.warning .pa-toc-item-line{
  background:var(--warning);
  opacity:0.9;
}

.pa-toc-item.danger .pa-toc-item-line{
  background:var(--danger);
  opacity:0.9;
}

.pa-toc-item-desc{
  width:70%;
  margin-top:20px;
}

.pa-toc-item-step-title{
  text-transform: uppercase;
  font-size:0.8em;
  opacity:0.8;
}

.pa-toc-item-line-curve{
  display:none;
}

.pa-toc-item .pa-toc-item-line{
  width:100%;
  position:relative;
}

.pa-toc-item .pa-toc-item-line-curve{
  position:relative;
  margin-left:auto;
  overflow:hidden;
  width:50px;
  height:50px;
  border-radius:50%;
  right:-24px;
}

.pa-toc-item .pa-toc-item-line-curve-segment{
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 100vw;
  height: 100vw;
  background: #62A1CF;
}

.pa-toc-item .pa-toc-item-line-curve-inner{
  width:38px;
  height:38px;
  border-radius:50%;
  background:var(--primary);
  position:relative;
  top:6px;
  left:6px;
}

.pa-toc-item .pa-toc-line-curve-dot{
  position:absolute;
  width:6px;
  height:6px;
  border-radius:50%;
  background:#62A1CF;
  top:0;
  left:21px;
}

.pa-toc-item .pa-toc-line-curve-dot.end{
  top:22px;
  left:44px;
}

.pa-toc-item:nth-child(5n) .pa-toc-item-line-curve{
  display:block;
}

.pa-toc-item:nth-child(5n) .pa-toc-item-line{
  width:60%;
}

.pa-toc-item.success .pa-toc-item-line-curve-segment,
.pa-toc-item.success .pa-toc-line-curve-dot{
  background:var(--success);
}

.pa-toc-newline{
  width:20%;
  position:relative;
  padding-right:20px;
}

.pa-toc-newline-line{
  position:relative;
  width:60%;
  margin-left:auto;
  height:6px;
  border-radius:4px;
  background:#62A1CF;
  top:29px;
}

.pa-toc-newline-line .pa-toc-item-line-curve{
  display:block;
  position:relative;
  overflow:hidden;
  width:50px;
  height:50px;
  border-radius:50%;
  left:-24px;
  top:-44px;
}

.pa-toc-newline-line .pa-toc-item-line-curve-segment{
  position: absolute;
  top: 50%;
  right: 50%;
  width: 100vw;
  height: 100vw;
  background: #62A1CF;
}

.pa-toc-item.warning .pa-toc-item-line-curve-segment{
  background:var(--warning);
}

.pa-toc-item.danger .pa-toc-item-line-curve-segment{
  background:var(--danger);
}

.pa-toc-item.success .pa-toc-item-line-curve-segment{
  background:var(--success);
}

.pa-toc-item.warning .pa-toc-line-curve-dot{
  background:var(--warning);
}

.pa-toc-item.danger .pa-toc-line-curve-dot{
  background:var(--danger);
}

.pa-toc-item.success .pa-toc-line-curve-dot{
  background:var(--success);
}

.pa-toc-newline-line .pa-toc-item-line-curve-inner{
  width:38px;
  height:38px;
  border-radius:50%;
  background:var(--primary);
  position:relative;
  top:6px;
  left:6px;
}

.pa-toc-newline-line .pa-toc-line-curve-dot{
  position:absolute;
  width:6px;
  height:6px;
  border-radius:50%;
  background:#62A1CF;
  top:44px;
  left:22px;
}

.pa-toc-newline-line .pa-toc-line-curve-dot.end{
  top:21px;
  left:0px;
}

.pa-splitscreen-container + .pa-form-timeline-wrapper,
.pa-fullscreen-container + .pa-form-timeline-wrapper{
  display:none;
}

.pa-fullscreen-container.completed + .pa-form-timeline-wrapper{
  display:flex;
}

.modal-body.pa-modal-preview-body {
  margin-bottom: 30px;
  margin-right: 30px;
  margin-left: 30px
}

.carousel-indicators{
  bottom: -45px !important;
}

.carousel-control-prev {
  left: -78px !important;
}

.carousel-control-next {
  right: -78px !important;
}

.pa-website-info-wrapper{
  padding:10px;
  border-radius:4px;
  background:var(--light-grey);
  color:var(--dark);
  text-align:left;
  min-width:450px;
}

.pa-website-info-wrapper h3{
  font-size:1.3rem;
}

@media screen and (max-width:750px){
  .pa-splitscreen-container.text .pa-splitscreen-col:first-child{
    display:none;
  }
  .pa-splitscreen-container.step .pa-splitscreen-col:nth-child(2){
    display:none;
  }
  .pa-splitscreen-col{
    width:100%;
  }
  .pa-toc-item{
    width:50%;
    padding:10px;
  }
  .pa-toc-newline{
    display:none;
  }
  .pa-toc-item:nth-child(5n) .pa-toc-item-line{
    width:100%;
  }
  .pa-toc-item:nth-child(5n) .pa-toc-item-line-curve{
    display:none;
  }
  .pa-toc-item:nth-child(2n) .pa-toc-item-line-curve{
    display:block;
  }
  .pa-toc-item:nth-child(2n) .pa-toc-item-line{
    width:60%;
  }
  .pa-field-wrapper{
    width:100%;
  }
}