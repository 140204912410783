@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@font-face {
    font-family: 'MontserratBold';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/Montserrat-Bold.ttf');
}

@font-face {
    font-family: 'MontserratBoldItalic';
    font-style: italic;
    font-weight: bold;
    src: url('../fonts/Montserrat-BoldItalic.ttf');
}

@font-face {
    font-family: 'MontserratMedium';
    font-style: normal;
    font-weight: medium;
    src: url('../fonts/Montserrat-Medium.ttf');
}

@font-face {
    font-family: 'MontserratMediumItalic';
    font-style: italic;
    font-weight: medium;
    src: url('../fonts/Montserrat-MediumItalic.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: normal;
    src: url('../fonts/Montserrat-Italic.ttf');
}

@font-face {
    font-family: 'MontserratLight';
    font-style: normal;
    font-weight: light;
    src: url('../fonts/Montserrat-Light.ttf');
}

@font-face {
    font-family: 'MontserratLightItalic';
    font-style: italic;
    font-weight: light;
    src: url('../fonts/Montserrat-LightItalic.ttf');
}
  
  