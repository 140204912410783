body {
  font-size:17px;
  line-height:1.4em;
  margin: 0;
  font-family: 'Open Sans', 'sans-serif', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, ::after, ::before {
  box-sizing: border-box;
}

body{
  overflow:hidden;
  box-sizing:border-box !important;
}

h1{
  line-height:1.4em;
  font-weight:500;
}

.pa-app-container{
  height:100vh;
  overflow-y:scroll;
}

.pa-container{
  max-width:1200px;
  width:100%;
  margin-left:auto;
  margin-right:auto;
  padding:0 10px;
}

.container-content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pa-app-container::-webkit-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for IE, Edge and Firefox */
.pa-app-container::-webkit-scrollbar {
  display: none;
}

.pa-page-container{
  width:100%;
  padding:20px 40px;
}


/*
* SIDE BAR 
*/
.pa-sidebar-wrapper{
  width:185px;
}

.pa-sidebar label{
  text-transform: uppercase;
  font-size:11px;
  display:block;
  margin:10px 0;
}

.pa-sidebar-link-wrapper,
.pa-sidebar-dropdown-title{
  display:block;
  margin:15px 0;
  font-size:16px;
  text-decoration:none;
  color:var(--light);
}

.pa-sidebar-dropdown-contents .pa-sidebar-link-wrapper{
  color:var(--dark);
  margin:10px 0;
}

.pa-sidebar-link{
  display:flex;
  align-items: center;
}

.pa-sidebar-link svg,
.pa-sidebar-dropdown-title svg{
  font-size:15px;
  margin-right:10px;
}

.pa-sidebar-dropdown-title{
  display:flex;
  justify-content: space-between;
}

.pa-sidebar-dropdown-contents{
  background-color:var(--light);
  border-radius:var(--border-radius);
  padding:10px;
  color:var(--dark);
}

/*
* SEARCH BAR
*/
.pa-searchbar-container {
  min-height: 60px;
  width: 100%;
  padding: 0 0 0 30px;
  z-index: 5;
}

.pa-searchbar-icon {
  width: 20px;
  height: 20px;
}

.pa-searchbar-wrapper {
  margin: 0 0 0 20px;
  width: 100%;
  position: relative;
}

.pa-searchbar-input {
  border: none;
  width: 90%;
  font-size: large;
}

.pa-searchbar-input:focus {
  color: var(--grey);
  outline: none;
}

.pa-searchbar-input::placeholder {
  font-size: 16px;
  color: var(--grey);
}

/*
* BUTTON STYLING
*/
.pa-btn-wrapper {
  display: block;
  text-align: center;
}

.pa-btn {
  padding: 8px 10px 8px 10px;
  border-radius: 15px;
  display: inline-block;
  cursor: pointer;
}

/*
* FOOTNOTE
*/

.pa-footnote {
  font-size: 0.9em;
}

input{
  font-family: 'Open Sans', 'sans-serif', 'Roboto', 'Oxygen';
}


/*
* POPUP
*/
.pa-popup-container {
  width: 100%;
  height: 100%;
  background-color: #66A5D0;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pa-popup-card {
  display: inline-block;
  background-color: var(--light);
  padding: 40px;
  border-radius: 20px;
  position: relative;
}

.pa-popup-children-wrapper {
  display: flex;
  justify-content: center;
}

.pa-popup-warning-wrapper {
  width: 70px;
}

.pa-popup-confirm-wrapper {
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0; 
}

.pa-popup-cancel-wrapper {
  position: absolute;
  top: -10px;
  right: -10px;
}

/*
* BUTTON
*/

.pa-btn {
  right: 100px;
  top: 80px;
  background-color: var(--primary);
  color: var(--light);
  padding:10px 25px;
  border-radius:25px;
  cursor:pointer;
  font-size:1.2em;
  display: inline-block;
  border: none;
  z-index: 8;
}

.pa-btn.outline {
  background-color: transparent;
  border: 2.5px solid var(--primary);
  color: var(--primary);
}

.pa-btn.icon-only {
  padding: 5px;
  border-radius:80px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.pa-btn.warning {
  background:var(--warning);
}

.link-to-admin{
  display:none;
  position: fixed;
  font-size: 12px;
  top: 5px;
  left: 5px;
  z-index: 999;
  color: 'black';
}

.is-admin-dashboard .link-to-admin{
  display:block;
}

.pa-input-error,
.pa-form-step-error{
  color:var(--danger);
  font-size:14px;
  font-style: italic;
}
.pa-form-step-error{
  text-align:right;
  margin-top:5px;
}